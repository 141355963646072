import React from "react";
import { Link } from "gatsby";
import { FaArrowRight } from "react-icons/fa";
import MailIcon from "../images/illustrations/undraw_mail.svg";
import logo from "../images/logo-miss-dulces-text-only-with-sub.svg";

function SuccessPage() {
  return (
    <div className="holder flex flex-col justify-start items-center w-screen h-screen">
      <Link
        to="/"
        className="my-6 mx-0 md:mx-16 lg:mx-32 xl:mx-32 w-32 lg:w-40"
      >
        <img src={logo} alt="" />
      </Link>
      <div className="w-full flex flex-col md:flex-row justify-around items-center px-6 md:px-16 lg:px-32 xl:px-32 flex-grow">
        <img src={MailIcon} className="w-32 md:w-64" />
        <div className="info flex flex-col justify-start my-6 md:ml-6 flex-grow">
          <h1 className="text-3xl lg:text-4xl font-bold mb-6 text-green-700 text-center md:text-left">
            Mensaje enviado!
          </h1>
          <Link
            to="/"
            className="bg-transparent hover:bg-myViolet text-myPurple font-semibold py-4 px-4 rounded border border-myPurple hover:border-myViolet self-start"
          >
            {" "}
            <FaArrowRight className="inline-block" /> Vuelve a la página
            principal!
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
